<template>
  <div>
    <div v-if="!setUserInSession" class="loading-spinner d-flex justify-content-center">
      <b-spinner variant="primary"/>
    </div>
    <div v-else>
      <transition name="overlay-fade"><div class="overlay" v-if="showOverlay"></div></transition>
      <div class="row">
          <div class="col-md-3">
              <label for="">Acceso directo</label>
              <div class="d-flex align-items-end">
                  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.98539 21.1696V8.19531H2.00269V21.1696H4.98539Z" :stroke="showRedirectRule ? '#1B4284' : '#6e6b7b'" stroke-width="2.204" stroke-linejoin="round"/>
                      <path d="M4.98584 8.19398C8.52681 4.34887 10.5789 2.14323 11.1421 1.5759C11.9872 0.724901 12.9417 1.08229 12.9417 3.21243C12.9417 5.34258 10.3144 6.57101 10.3144 8.19398C10.3125 8.20342 13.673 8.20401 20.3945 8.19575C20.5904 8.19544 20.7845 8.24094 20.9656 8.32964C21.1467 8.41834 21.3113 8.5485 21.45 8.7127C21.5887 8.87689 21.6987 9.07189 21.7738 9.28656C21.849 9.50123 21.8877 9.73136 21.8878 9.9638V9.96557C21.8878 10.4354 21.7305 10.886 21.4504 11.2183C21.1704 11.5505 20.7905 11.7372 20.3945 11.7372H16.4126C15.8121 16.4392 15.4805 18.9951 15.4183 19.4062C15.3249 20.0218 14.8293 21.1683 13.403 21.1683H4.98584V8.19398Z" :stroke="showRedirectRule ? '#1B4284' : '#6e6b7b'" stroke-width="2.204" stroke-linejoin="round"/>
                  </svg>
                  <a
                      v-if="showRedirectRule"
                      variant="link"
                      @click="goToRule()"
                      target="_blank"
                      class="p-0 m-0"
                  >
                      <span class="ml-50 link-color">Regla Activa</span>
                  </a>
                  <span v-else class="ml-50 font-weight-500">Regla Activa</span>
                  
              </div>
              <p class="ml-2 link-color">
                  <small>
                      <span class="table_dot--company mr-50" :class="getStatusClass(infoRuleCompany)"></span> 
                      <span v-if="infoRuleCompany">{{ abbreviateText(infoRuleCompany.name, 34) }}</span>
                      <span v-else>No tiene regla asignada</span>
                  </small>
              </p>
          </div>
          <div class="col-md-3" v-if="!!isAdmin">
              <label for="">Acceso directo</label>
              <div class="d-flex align-items-end">
                  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.98539 21.1696V8.19531H2.00269V21.1696H4.98539Z" stroke="#1B4284" stroke-width="2.204" stroke-linejoin="round"/>
                      <path d="M4.98584 8.19398C8.52681 4.34887 10.5789 2.14323 11.1421 1.5759C11.9872 0.724901 12.9417 1.08229 12.9417 3.21243C12.9417 5.34258 10.3144 6.57101 10.3144 8.19398C10.3125 8.20342 13.673 8.20401 20.3945 8.19575C20.5904 8.19544 20.7845 8.24094 20.9656 8.32964C21.1467 8.41834 21.3113 8.5485 21.45 8.7127C21.5887 8.87689 21.6987 9.07189 21.7738 9.28656C21.849 9.50123 21.8877 9.73136 21.8878 9.9638V9.96557C21.8878 10.4354 21.7305 10.886 21.4504 11.2183C21.1704 11.5505 20.7905 11.7372 20.3945 11.7372H16.4126C15.8121 16.4392 15.4805 18.9951 15.4183 19.4062C15.3249 20.0218 14.8293 21.1683 13.403 21.1683H4.98584V8.19398Z" stroke="#1B4284" stroke-width="2.204" stroke-linejoin="round"/>
                  </svg>
                  <a 
                      @click="goToWebhooks()"
                      target="_blank" 
                      rel="noopener noreferrer"
                      variant="link"
                      class="p-0 m-0"
                  >
                      <span class="ml-50 link-color">Webhooks</span>
                  </a>
              </div>
          </div>
          <div class="col-md-3">
              <label for="">Acceso directo</label>
              <div class="d-flex align-items-end">
                  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.98539 21.1696V8.19531H2.00269V21.1696H4.98539Z" stroke="#1B4284" stroke-width="2.204" stroke-linejoin="round"/>
                      <path d="M4.98584 8.19398C8.52681 4.34887 10.5789 2.14323 11.1421 1.5759C11.9872 0.724901 12.9417 1.08229 12.9417 3.21243C12.9417 5.34258 10.3144 6.57101 10.3144 8.19398C10.3125 8.20342 13.673 8.20401 20.3945 8.19575C20.5904 8.19544 20.7845 8.24094 20.9656 8.32964C21.1467 8.41834 21.3113 8.5485 21.45 8.7127C21.5887 8.87689 21.6987 9.07189 21.7738 9.28656C21.849 9.50123 21.8877 9.73136 21.8878 9.9638V9.96557C21.8878 10.4354 21.7305 10.886 21.4504 11.2183C21.1704 11.5505 20.7905 11.7372 20.3945 11.7372H16.4126C15.8121 16.4392 15.4805 18.9951 15.4183 19.4062C15.3249 20.0218 14.8293 21.1683 13.403 21.1683H4.98584V8.19398Z" stroke="#1B4284" stroke-width="2.204" stroke-linejoin="round"/>
                  </svg>
                  <a 
                      @click="goToPudos()"
                      target="_blank" 
                      rel="noopener noreferrer"
                      variant="link"
                      class="p-0 m-0"
                  >
                      <span class="ml-50 link-color">Pudos</span>
                  </a>
                  
              </div>
          </div>
      </div>
      <div class="position-relative mt-1">
          <b-button 
            v-if="showBtn.edit && !!isAdmin && !loadingInfoCompany" 
            class="d-flex align-items-center position-absolute custom-position-edit" 
            variant="outline-primary" 
            @click="enabledFields">
              <feather-icon
              size="16"
              icon="Edit2Icon"
              class="mr-50"
              />Editar
          </b-button>
          <form-render
              ref="formRenderCompany"
              @send="handleSubmitEdit"
              :key="keyFormRender" 
              class="px-0" 
              :fields="fields" 
              :form.sync="form"
              containerButtonsClass="col-md-4 col-xl-3 mt-2" 
          >
          <template #generateApiKey>
              <b-button
                variant="link"
                class="m-0 p-0"
                @click="confirmChangeApikey"
                :disabled="loading.getApiKey"
              >
                <span class="d-flex"
                  ><b-spinner small v-if="loading.getApiKey" class="mr-1" />Actualizar API KEY</span
                >
              </b-button>
          </template>
          <template #expanderCustomization>
            <div class="position-absolute custom-position-expander">
              <b-button
                  variant="link"
                  class="m-0 p-0"
                  @click="openModalExpander(CONDITIONS.CUSTOMIZATION)"
                >
                <feather-icon
                    class="text-primary"
                    icon="Maximize2Icon"
                    size="20"
                  />
              </b-button>
            </div>
          </template>
          <template #expanderDefault>
            <div class="position-absolute custom-position-expander">
              <b-button
                  variant="link"
                  class="m-0 p-0"
                  @click="openModalExpander(CONDITIONS.DEFAULT)"
                >
                <feather-icon
                    class="text-primary"
                    icon="Maximize2Icon"
                    size="20"
                  />
              </b-button>
            </div>
          </template>
          </form-render>
          <div v-if="showBtn.saved" class="d-flex justify-content-end">
            <b-button @click="validateForm" variant="warning" :disabled="isOriginalForm || loading.savedChangeCompany">
              <span v-if="!loading.savedChangeCompany" class="mb-1">Guardar cambios</span>
              <span class="px-2" v-else ><b-spinner small /> </span>
            </b-button>
          </div> 
      </div>
      <modal-expander ref="modalExpander" :fieldExpander="fieldExpander" :disabledModal="showBtn.edit" @updateField="updateField"/>
    </div>
  </div>
</template>

<script>
import BaseServices from '@/store/services/index'
import { environment } from '@/environments/environment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ModalExpander from '../modals/ModalExpander.vue'
import isEqual from 'lodash/isEqual'
export default {
  name: 'operation-details',
  components: {ModalExpander},
  props: ['company_data', 'setUserInSession', 'loadingInfoCompany'],
  data () {
    return {
      company_id: this.$route.params?.id,
      infoRuleCompany: null,
      baseService: new BaseServices(this),
      showRedirectRule: false,
      isAdmin: false,
      showBtn: {
        edit: true,
        saved: false
      },
      keyFormRender: 0,
      fields: [],
      form: {},
      loading: {
        getApiKey: false,
        savedChangeCompany: false
      },
      fieldExpander: null,
      CONDITIONS: Object.freeze({
        CUSTOMIZATION: 'customization',
        DEFAULT: 'default_setting'
      }),
      showOverlay: false,
      isOriginalForm: false
    }
  },
  watch: {
    setUserInSession (value) {
      if (!!value) this.setRoleUsers()
    },
    company_data () {
      this.setForm()
    },
    form: {
      handler (val, oldVal) {
        if (val && this.originalForm) {  
          this.currentForm = {
            default_setting: val.default_setting || '',
            customization: val.customization || ''
          }

          if (isEqual(this.currentForm, this.originalForm)) 
          {
            this.isOriginalForm = true
          } 
          else 
          {
            this.isOriginalForm = false
          }
        }
        
      },
      deep: true
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.setField()
      this.setRule()
      if (!!this.setUserInSession) this.setRoleUsers()
    },
    setField () {
      this.fields = [
        {fieldType: 'FieldInput', useLabel: true, name: 'api_key', label: 'API KEY', containerClass: 'container-info col-8 col-md-8 col-lg-8', validation: 'required', disabled: true },
        {name: 'generateApiKey', useSlot: true, containerClass: 'container-info col-4 align-link-api-key align-items-start' },
        {name: 'expanderCustomization', useSlot: true, containerClass: 'hide' },
        {fieldType: 'FieldTextarea', useLabel: true, name: 'customization', label: 'Configuraciones personalizadas', containerClass: 'hide', rows: 4, disabled: true },
        {name: 'expanderDefault', useSlot: true, containerClass: 'hide' },
        {fieldType: 'FieldTextarea', useLabel: true, name: 'default_setting', label: 'Configuraciones por default', containerClass: 'hide', disabled: true }
      ]
      this.setForm()
    },
    setForm () {
      if (!!this.company_data) {
        const customization = this.company_data.company.customization ?? ''
        const default_setting = this.company_data.company.default_setting ?? ''
        this.form = {
          ...this.company_data.company,
          customization: this.setStringFields(customization),
          default_setting: this.setStringFields(default_setting)
        }
        this.originalForm = {
          default_setting: this.setStringFields(default_setting),
          customization: this.setStringFields(customization)
        }
      }
    },
    setStringFields (data) {
      return !!data && typeof data === 'object' ? JSON.stringify(data) : data
    },
    setRoleUsers () {
      this.isAdmin = ['admin', 'superadmin'].includes(this.$session.get('cas_user').role)
      this.isSellerCencosudProd = ['seller'].includes(this.$session.get('cas_user').role) && environment.platformUrl === 'https://platform.enviame.io' && [1162].includes(this.form?.dubai_organization_id)
      this.setDinamicField()
    },
    setDinamicField () {
      this.fields.map(field => {
        if (['expanderCustomization', 'expanderDefault'].includes(field.name) && this.isAdmin) field.containerClass = 'container-info col-12'
        if (['customization', 'default_setting'].includes(field.name) && this.isAdmin) field.containerClass = 'container-info col-12 col-md-12 col-lg-12 mt-1'
      })
      if (!!this.isSellerCencosudProd) {
        const deleteFieldsForCencosud = ['generateApiKey', 'api_key']
        deleteFieldsForCencosud.forEach((el) => {
          const index = this.fields.findIndex((field) => field.name === el)
          this.fields.splice(index, 1)
        })
      }
    },
    enabledFields () {
      this.disabledFields(false)
      this.showBtn.edit = false
      this.showBtn.saved = true
    },
    disabledFields (disabled) {
      const alwaysDisabledField = ['api_key']
      const fieldsFilter = this.fields.filter(field => !alwaysDisabledField.includes(field.name))
      fieldsFilter.map(item => {
        item.disabled = disabled
      })
    },
    setRule () {
      const queryParams = {}
      const params = {company_id: this.company_id}
      this.baseService.callService('getCompanyRules', queryParams, params)
        .then(resp => {
          this.showRedirectRule = !!resp.data &&  !!resp?.data?.self_link
          this.infoRuleCompany = resp?.data || null
        })
        .catch(err => {
          this.showRedirectRule = false
          this.$alert(this.$t('msg-problema-cargar', {code: err}))
        })
    },
    getStatusClass(status) {
      return !!status ? 'table_dot--company-ok' : 'table_dot--company-inactivated'
    },
    goToRule () {
      const { self_link } = this.infoRuleCompany
      window.open(`${environment.platformUrl}${self_link}`, '_blank')
    },
    goToPudos () {
      let urlPudos = 'pudos-beta'
      if (!!this.isAdmin) {
        const {dubai_organization_id, dubai_shipper_id, marketplace} = this.company_data.company
        urlPudos = `pudos-beta?company_id=${dubai_organization_id}`
        if (!marketplace && dubai_organization_id !== dubai_shipper_id) urlPudos = `pudos-beta?company_id=${dubai_organization_id}&seller_id=${dubai_shipper_id}`
      }
      window.open(`${environment.platformUrl}/${urlPudos}`, '_blank')
      /* this.$router.push({path: `/${urlPudos}`}) */
    },
    goToWebhooks () {
      this.$emit('changeTabNotifications')
    },
    abbreviateText (text, maximumLength) {
      if (text.length <= maximumLength) {
        return text
      } else {
        return `${text.substring(0, maximumLength)}...`
      }
    },
    validateForm () {
      this.$refs.formRenderCompany.checkForm()
    },
    handleSubmitEdit () {
      if (!!this.company_data.validate_authority_contacts) {
        const dataToastNotification = {
          title: 'Oops',
          message: 'Debe incluir al menos un contacto tomador de decisión',
          variant: 'danger',
          icon: 'warning'
        }
        this.showToastNotificationError(dataToastNotification)
        this.$emit('openCollapseAuthorityContacts')
      } else {
        this.loading.savedChangeCompany = true
        const payload = this.m_findChangedFields(this.currentForm, this.originalForm)
        const parsedPayload = Object.fromEntries(
          Object.entries(payload).map(([key, value]) => {
            try {
              return [key, JSON.parse(value)]
            } 
            catch {
              return [key, value]
            }
          })
        )
        this.showOverlay = true
        this.baseService.callService('putEditCompanyNew', parsedPayload, {company_id: this.form.id})
          .then(resp => {
            this.$emit('getCompanyInfoEdition')
            this.disabledFields(true)
            this.showBtn.edit = true
            this.showBtn.saved = false 
            this.keyFormRender++
            this.$newSuccess('Excelente', 'La empresa se ha actualizado correctamente')
          })
          .catch(err => {
            const dataToastNotification = {
              title: 'Oops',
              message: `La empresa no se ha actualizado. Error: ${err.message}`,
              variant: 'danger',
              icon: 'warning'
            }
            this.showToastNotificationError(dataToastNotification)
          })
          .finally(() => {
            this.keyFormRender++
            this.loading.savedChangeCompany = false
            this.showOverlay = false
          })
      }
    },
    confirmChangeApikey() {
      this.$confirm(this.$t('¿Está seguro que desea generar una nueva apikey?'), () => this.getApiKey())
    },
    getApiKey() {
      const params = {company_id: this.form.id}
      const queryParams =  {}
      this.loading.getApiKey = true
      this.baseService.callService('getApiKey', queryParams, params)
        .then(resp => {
          this.keyFormRender++
          this.setApiKey(resp.data.token)
          this.$newSuccess('Excelente', 'La API KEY se ha actualizado correctamente')
        })
        .catch(err => {
          const dataToastNotification = {
            title: 'Oops',
            message: `La API KEY no se ha actualizado. Error: ${err.message}`,
            variant: 'danger',
            icon: 'WarningIcon'
          }
          this.showToastNotificationError(dataToastNotification)
        })
        .finally(() => {
          this.loading.getApiKey = false
        })
    },
    setApiKey(apikey) {
      this.setSkeletonField('api_key', true)
      this.form.api_key = apikey
      this.keyFormRender++
      setTimeout(() => {
        this.setSkeletonField('api_key', false)
      }, 100)
    },
    setSkeletonField(name, bool) {
      const index = this.fields.findIndex((el) => el.name === name)
      if (index !== -1) {
        this.fields[index].useSlot = bool
        this.fields[index].useSkeleton = bool
        this.fields[index].useLabel = bool
      }
    },
    showToastNotificationError(dataToastNotification) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: dataToastNotification.title,
          icon: dataToastNotification.icon,
          variant: dataToastNotification.variant,
          text: dataToastNotification.message
        }
      })
    },
    openModalExpander (type) {
      this.fieldExpander = type === this.CONDITIONS.CUSTOMIZATION ? {name: this.CONDITIONS.CUSTOMIZATION, title: 'Configuraciones personalizadas', value: this.form.customization || ''} : {name: this.CONDITIONS.DEFAULT, title: 'Configuraciones por default', value: this.form.default_setting || ''}
      this.$bvModal.show('modalExpander')
    },
    updateField (field) {
      this.form[field.name] = field.value
      this.keyFormRender++
    }
  }
}
</script>

<style>
.link-color{
  color: #1B4284;
  font-weight: 500;
}
.align-link-api-key {
  align-self: center;
  align-items: center;
  margin-bottom: 0;
  margin-top: 6px;

}
.custom-position-edit {
  top: -25px;
  right: 0;
}
.custom-position-expander {
  right: 20px;
  top: 20px;
  z-index: 2;
}
</style>